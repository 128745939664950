import React from 'react';
import { Button } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import './CustomButton.css';

const CustomButton = ({ onClick, text }) => {
    return (
        <Button className="custom-button" onClick={onClick}>
            {text} <FaArrowRight className="icon" />
        </Button>
    );
};

export default CustomButton;

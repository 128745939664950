import { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import BookingBanner from "./BookingBanner";
import "./Hero.css"; // Custom CSS file for Hero component

const slides = [
  {
    image: "/images/christmas/hero/christmas-1.png",
    title: "Slide 1 Title",
    description: "This is the first slide description.",
  },
  {
    image: "/images/christmas/hero/christmas-2.png",
    title: "Slide 2 Title",
    description: "This is the second slide description.",
  },
  {
    image: "/images/christmas/hero/christmas-3.png",
    title: "Slide 3 Title",
    description: "This is the third slide description.",
  },
  {
    image: "/images/christmas/hero/christmas-4.png",
    title: "Slide 4 Title",
    description: "This is the fourth slide description.",
  },
];

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 5000); // Change slide every 5 seconds
    return () => clearInterval(slideInterval);
  }, []);

  return (
    <section className="position-relative w-100 overflow-hidden d-flex align-items-center hero-section">
      {/* Hero Slider Section */}
      <div className="position-relative w-100 overflow-hidden hero-slider">
        {/* Slide Backgrounds */}
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`position-absolute w-100 h-100 bg-cover bg-center transition-opacity ${
              index === currentSlide ? "opacity-1" : "opacity-0"
            }`}
            style={{
              backgroundImage: `url(${slide.image})`,
              transition: "opacity 1s ease",
            }}
          ></div>
        ))}

        <div
          className="position-absolute booking-banner-container"
          style={{
            zIndex: 2,
          }}
        >
          <BookingBanner />
        </div>

        {/* Text Overlay in Between Arrows */}
        <div className="position-absolute top-50 start-50 translate-middle text-overlay d-flex justify-content-center">
          <div className="overlay-text bg-dark bg-opacity-50 text-white py-3 px-4 rounded">
            <h1 className="fw-bold mb-0 text-center hero-title">
              Christmas Light <br /> Installation & Decoration
            </h1>
          </div>
        </div>

        {/* Navigation Arrows */}
        <button
          onClick={prevSlide}
          className="position-absolute start-0 top-50 translate-middle-y arrow-btn p-2"
        >
          <FaChevronLeft className="text-white fs-4" />
        </button>
        <button
          onClick={nextSlide}
          className="position-absolute end-0 top-50 translate-middle-y arrow-btn p-2"
        >
          <FaChevronRight className="text-white fs-4" />
        </button>
      </div>
    </section>
  );
};

export default Hero;

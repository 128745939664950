import React, { useState } from "react";
import { FiPhone, FiCalendar } from "react-icons/fi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import callbackImage from "../../../assets/images/christmas/callback.png";
import emailjs from "@emailjs/browser";
import "./CallbackSection.css";

const CallbackSection = () => {
  const [installationDate, setInstallationDate] = useState(null);
  const [takeDownDate, setTakeDownDate] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    streetAddress: "",
    city: "",
    zip: "",
    hours: "",
    specialRequest: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const templateParams = {
      ...formData,
      startDate: installationDate ? installationDate.toLocaleDateString() : "",
      endDate: takeDownDate ? takeDownDate.toLocaleDateString() : "",
      from_name: formData.firstName + " " + formData.lastName,
    };

    emailjs
      .send(
        "service_kq8hf5c",
        "template_1ar0yvf",
        templateParams,
        "FuqNV8mUCsn7GdGTO"
      )
      .then(
        (response) => {
          alert("Invitation sent successfully");
          setLoading(false);
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            streetAddress: "",
            city: "",
            zip: "",
            hours: "",
            specialRequest: "",
          });
          setInstallationDate(null);
          setTakeDownDate(null);
        },
        (err) => {
          console.error("FAILED...", err);
          setLoading(false);
        }
      );
  };

  return (
    <div
      className="container d-flex flex-column flex-lg-row justify-content-center gap-5"
      style={{ margin: "7rem auto" }}
    >
      <div className="callback-form p-4 rounded w-100">
        <h2 className="text-center fs-4 fw-bold mb-4">
          Book Your Installation
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                name="firstName"
                placeholder="First Name*"
                className="styled-input form-control form-control-lg"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name*"
                className="styled-input form-control form-control-lg"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <input
                type="email"
                name="email"
                placeholder="Email Address*"
                className="styled-input form-control-lg"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col">
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Your Phone Number"
                className="styled-input form-control-lg"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                name="streetAddress"
                placeholder="Street Address"
                className="styled-input form-control-lg"
                value={formData.streetAddress}
                onChange={handleChange}
              />
            </div>
            <div className="col">
              <input
                type="text"
                name="city"
                placeholder="City"
                className="styled-input form-control-lg"
                value={formData.city}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                name="zip"
                placeholder="Zip"
                className="styled-input form-control-lg"
                value={formData.zip}
                onChange={handleChange}
              />
            </div>
            <div className="col">
              <select
                name="hours"
                className="styled-input form-select form-control-lg"
                value={formData.hours}
                onChange={handleChange}
              >
                <option value="">Select Hours</option>
                {Array.from({ length: 8 }, (_, i) => {
                  const startHour = 9 + i; // Starting from 9 AM
                  const endHour = startHour + 1;
                  return (
                    <option key={i} value={`${startHour}-${endHour}`}>
                      {`${startHour}:00 - ${endHour}:00`}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <div className="input-group">
                <span className="input-group-text styled-input-icon">
                  <FiCalendar />
                </span>
                <DatePicker
                  selected={installationDate}
                  onChange={(date) => setInstallationDate(date)}
                  placeholderText="Installation Date"
                  className="styled-input form-control-lg"
                />
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <span className="input-group-text styled-input-icon">
                  <FiCalendar />
                </span>
                <DatePicker
                  selected={takeDownDate}
                  onChange={(date) => setTakeDownDate(date)}
                  placeholderText="Take Down Date"
                  className="styled-input form-control-lg"
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <textarea
              name="specialRequest"
              placeholder="How can we make your holiday dreams come true?*"
              className="styled-input form-control-lg"
              rows="4"
              value={formData.specialRequest}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="btn btn-danger w-100 text-white fw-bold shadow fs-5"
            disabled={loading}
          >
            {loading ? "Sending..." : "Book Today!"}
          </button>
        </form>
      </div>

      <div className="callback-image-container w-100 position-relative">
        <img
          src={callbackImage}
          alt="Decorated Christmas Tree"
          className="img-fluid rounded shadow callback-image"
        />
        <div className="contact-info-box position-absolute bg-white p-3 rounded shadow-lg">
          <p className="text-primary fw-bold small mb-1 fs-4">
            Call for Booking
          </p>
          <a href="tel:+16029385415">
            <div className="d-flex align-items-center text-dark fs-3 fw-bold">
              <FiPhone className="me-2" />
              <span>602-938-5415</span>
            </div>
          </a>
          <p className="text-muted text-decoration-underline fw-semibold small mt-2">
            NOT A LICENSED CONTRACTOR
          </p>
        </div>
      </div>
    </div>
  );
};

export default CallbackSection;

const CustomerTerms = () => {
  return (
    <>
      {/* Job Verification Process */}
      <div class="accordion-item border border-dark rounded-3 mb-3">
        <h2 class="accordion-header" id="serviceGuidelinesAccordion">
          <button
            class="accordion-button bg-warning text-dark border-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseBeforeStarting"
            aria-expanded="true"
            aria-controls="collapseBeforeStarting"
          >
            Job Verification Process
          </button>
        </h2>
        <div
          id="collapseBeforeStarting"
          class="accordion-collapse collapse show"
          aria-labelledby="serviceGuidelinesAccordion"
          data-bs-parent="#serviceGuidelinesAccordion"
        >
          <div class="accordion-body bg-white text-dark">
            <ul class="mb-0">
              <li>The Handyman Will Verify the Job When They Get Onsite.</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Onsite Communication */}
      <div class="accordion-item border border-dark rounded-3 mb-3">
        <h2 class="accordion-header" id="onSiteCommunication">
          <button
            class="accordion-button bg-warning text-dark border-dark collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOnSiteCommunication"
            aria-expanded="false"
            aria-controls="collapseOnSiteCommunication"
          >
            Onsite Communication
          </button>
        </h2>
        <div
          id="collapseOnSiteCommunication"
          class="accordion-collapse collapse"
          aria-labelledby="onSiteCommunication"
          data-bs-parent="#serviceGuidelinesAccordion"
        >
          <div class="accordion-body bg-white text-dark">
            <ul class="mb-0">
              <li>
                They Are The Handyman on Your Job; Any Questions or Concerns
                Should Be Addressed Onsite.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Service Charges */}
      <div class="accordion-item border border-dark rounded-3 mb-3">
        <h2 class="accordion-header" id="serviceCharges">
          <button
            class="accordion-button bg-warning text-dark border-dark collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseServiceCharges"
            aria-expanded="false"
            aria-controls="collapseServiceCharges"
          >
            Service Charges
          </button>
        </h2>
        <div
          id="collapseServiceCharges"
          class="accordion-collapse collapse"
          aria-labelledby="serviceCharges"
          data-bs-parent="#serviceGuidelinesAccordion"
        >
          <div class="accordion-body bg-white text-dark">
            <ul class="mb-0">
              <li>Minimum One Hour Call/Fee.</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Cancellation Policy */}
      <div class="accordion-item border border-dark rounded-3 mb-3">
        <h2 class="accordion-header" id="cancellationPolicy">
          <button
            class="accordion-button bg-warning text-dark border-dark collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseCancellationPolicy"
            aria-expanded="false"
            aria-controls="collapseCancellationPolicy"
          >
            Cancellation Policy
          </button>
        </h2>
        <div
          id="collapseCancellationPolicy"
          class="accordion-collapse collapse"
          aria-labelledby="cancellationPolicy"
          data-bs-parent="#serviceGuidelinesAccordion"
        >
          <div class="accordion-body bg-white text-dark">
            <ul class="mb-0">
              <li>24-Hour Cancellation Fee Equal to One Hour of Work.</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Hourly Rate */}
      <div class="accordion-item border border-dark rounded-3 mb-3">
        <h2 class="accordion-header" id="hourlyRate">
          <button
            class="accordion-button bg-warning text-dark border-dark collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseHourlyRate"
            aria-expanded="false"
            aria-controls="collapseHourlyRate"
          >
            Hourly Rate
          </button>
        </h2>
        <div
          id="collapseHourlyRate"
          class="accordion-collapse collapse"
          aria-labelledby="hourlyRate"
          data-bs-parent="#serviceGuidelinesAccordion"
        >
          <div class="accordion-body bg-white text-dark">
            <ul class="mb-0">
              <li>Our Standard Hourly Rate is $99.</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Payment Process */}
      <div class="accordion-item border border-dark rounded-3 mb-3">
        <h2 class="accordion-header" id="paymentProcess">
          <button
            class="accordion-button bg-warning text-dark border-dark collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsePaymentProcess"
            aria-expanded="false"
            aria-controls="collapsePaymentProcess"
          >
            Payment Process
          </button>
        </h2>
        <div
          id="collapsePaymentProcess"
          class="accordion-collapse collapse"
          aria-labelledby="paymentProcess"
          data-bs-parent="#serviceGuidelinesAccordion"
        >
          <div class="accordion-body bg-white text-dark">
            <ul class="mb-0">
              <li>Payment by Debit or Credit at the End of the Appointment.</li>
              <li>The invoice must be paid while they are onsite.</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Material Shopping */}
      <div class="accordion-item border border-dark rounded-3 mb-3">
        <h2 class="accordion-header" id="materialShopping">
          <button
            class="accordion-button bg-warning text-dark border-dark collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseMaterialShopping"
            aria-expanded="false"
            aria-controls="collapseMaterialShopping"
          >
            Material Shopping
          </button>
        </h2>
        <div
          id="collapseMaterialShopping"
          class="accordion-collapse collapse"
          aria-labelledby="materialShopping"
          data-bs-parent="#serviceGuidelinesAccordion"
        >
          <div class="accordion-body bg-white text-dark">
            <ul class="mb-0">
              <li>One Hour Allotted for Materials Shopping Without Markup.</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Scheduling and Contact */}
      <div class="accordion-item border border-dark rounded-3 mb-3">
        <h2 class="accordion-header" id="schedulingContact">
          <button
            class="accordion-button bg-warning text-dark border-dark collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseSchedulingContact"
            aria-expanded="false"
            aria-controls="collapseSchedulingContact"
          >
            Scheduling and Contact
          </button>
        </h2>
        <div
          id="collapseSchedulingContact"
          class="accordion-collapse collapse"
          aria-labelledby="schedulingContact"
          data-bs-parent="#serviceGuidelinesAccordion"
        >
          <div class="accordion-body bg-white text-dark">
            <ul class="mb-0">
              <li>
                For Customer Service or Handyman Questions: Handyman Manager
                Phone Number: 623-300- 2859.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Warranty and Follow-Up */}
      <div class="accordion-item border border-dark rounded-3 mb-3">
        <h2 class="accordion-header" id="warrantyFollowUp">
          <button
            class="accordion-button bg-warning text-dark border-dark collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseWarrantyFollowUp"
            aria-expanded="false"
            aria-controls="collapseWarrantyFollowUp"
          >
            Warranty and Follow-Up
          </button>
        </h2>
        <div
          id="collapseWarrantyFollowUp"
          class="accordion-collapse collapse"
          aria-labelledby="warrantyFollowUp"
          data-bs-parent="#serviceGuidelinesAccordion"
        >
          <div class="accordion-body bg-white text-dark">
            <ul class="mb-0">
              <li>
                The Office will schedule a comeback visit for the handyman who
                did the job.
              </li>
              <li>
                The handyman will come back to look at the customer issue to
                determine if it is:
              </li>
              <ul>
                <li>
                  a warranty call, where the handyman made a mistake or did not
                  complete the job properly.
                </li>
                <li>
                  another issue, where the problem is not apparent and requires
                  further assistance, which will be a new call with additional
                  charges.
                </li>
                <li>
                  a manufacturer defect, where the handyman will work with the
                  owner to find an affordable solution.
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerTerms;

import React, { useEffect } from "react";

function Map() {
  // Define the onPluginReady function outside of useEffect
  //   function onPluginReady() {
  //     window.rwlPlugin.init('https://app.realworklabs.com', 'xE4qsz_LutZHAWFB');
  //     // Remove the event listener after the script has executed once
  //     window.removeEventListener('rwlPluginReady', onPluginReady);
  //   }
  function onPluginReady() {
    if (!window.rwlPlugin.isInitialized) {
      window.rwlPlugin.init("https://app.realworklabs.com", "xE4qsz_LutZHAWFB");
    }
    // Remove the event listener after the script has executed once
    //    window.removeEventListener('rwlPluginReady', onPluginReady);
  }

  useEffect(() => {
    // Define your script as a function
    function loadScript() {
      var d = document,
        t = "script",
        o = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      o.src =
        "https://app.realworklabs.com/static/plugin/loader.js?v=" +
        new Date().getTime();
      window.addEventListener("rwlPluginReady", onPluginReady, false);
      s.parentNode.insertBefore(o, s);
    }

    // Call the script function when the component mounts
    loadScript();

    // Clean up the script when the component unmounts
    return () => {
      // Remove the event listener
      window.removeEventListener("rwlPluginReady", onPluginReady);
    };
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  return (
    <div>
      {/* <div id="rwl-map"></div> */}
      <iframe src="map.html" width="100%" height="800px"></iframe>
    </div>
  );
}

export default Map;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Rates from "../Rates/Rates";

export default function CarePlans() {
  const [showBasic, setShowBasic] = useState("Show More");
  const [showPremium, setShowPremiun] = useState("Show More");
  const [showVip, setShowVip] = useState("Show More");

  const [basic, setBasic] = useState("none");
  const [premium, setPremium] = useState("none");
  const [vip, setVip] = useState("none");

  const handleBasic = () => {
    if (showBasic === "Show More") {
      setShowBasic("Show Less");
    } else {
      setShowBasic("Show More");
    }

    if (basic === "block") {
      setBasic("none");
    } else {
      setBasic("block");
    }
  };
  const handlePremium = () => {
    if (showPremium === "Show More") {
      setShowPremiun("Show Less");
    } else {
      setShowPremiun("Show More");
    }
    if (premium === "block") {
      setPremium("none");
    } else {
      setPremium("block");
    }
  };
  const handleVip = () => {
    if (showVip === "Show More") {
      setShowVip("Show Less");
    } else {
      setShowVip("Show More");
    }
    if (vip === "block") {
      setVip("none");
    } else {
      setVip("block");
    }
  };
  return (
    <div>
      <section class="breakthrough-page-banner-area">
        <div class="container">
          <div class="video-container" style={{ zIndex: "-10" }}>
            <video autoPlay muted loop id="myVideo">
              <source src="./videos/care-plans.mp4" type="video/mp4" />
            </video>
          </div>

          <div class="row">
            <div class="col-12 banner-head-div">
              <h2 class="banner-head-txt">Complete Care Plans</h2>
            </div>
          </div>
        </div>
      </section>
      <div class="fix-it-plan-main">
        <section class="plans-section">
          <div class="plans-section-wrapper">
            <div class="container">
              <div class="plans-section-content-row">
                <div class="plans-section-text-div">
                  <div class="plans-section-head-div">
                    <h3 class="plans-sect-head-text">
                      What are Complete Care Plans?
                    </h3>
                  </div>
                  <div class="plans-sect-meta-div">
                    <p class="breakthrough-para">
                      Home maintenance requires regular attention and can be
                      overwhelming. Let us help!
                    </p>
                    <p class="breakthrough-para">
                      Our Complete Care Plans take the stress out of planning by
                      automating your home maintenance needs. We offer three
                      tiers of service. Here's how it works:
                    </p>
                  </div>
                  <div className="container text-center">
                    <iframe
                      className="video-container-youtube"
                      width="1024"
                      height="576"
                      src="https://www.youtube.com/embed/PTRoWGu0KD8"
                      title="Complete Home Care Plans from Breakthrough Handyman Services"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="row contact-form-manin-row my-5">
                <div
                  class="col-12 contact-form-main-col "
                  style={{ textAlign: "center" }}
                >
                  <a
                    class="ctaButton-white"
                    href="https://www.thecustomerfactor.com/bid.php?id=am9zaHVham1lNQ=="
                    target="_blank"
                  >
                    Click Here to Schedule An Appointment
                  </a>
                </div>
              </div>

              <div class="row my-5 plans-section-price-listing-row">
                <div class="col-lg-12 single-price-listing-div">
                  <div className="row  mx-auto single-price-listing-wrapper">
                    <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                      <div class="single-price-feature-div">
                        <h3 class="single-price-feature-text">Basic</h3>
                      </div>
                      <div class="single-price-amount-div">
                        <h3 class="single-price-amount-text">$149/mo</h3>
                      </div>
                      <div class="single-price-meta-div">
                        <h4 class="single-price-meta-head">What you get:</h4>
                        <ul class="single-price-listing-ul">
                          <li class="single-price-li">
                            2 hours of work per month
                          </li>
                          {/* <li class="single-price-li">15% off on any work</li> */}
                          <li class="single-price-li">
                            15% off billable hours after credited work.
                          </li>
                        </ul>

                        <div style={{ display: `${basic}` }}>
                          <div class="benefits-heading">
                            <h4 class="single-price-meta-head benefits-heading-txt">
                              Plan Benefits:
                            </h4>
                          </div>
                          <ul class="single-price-listing-ul">
                            <li class="single-price-li">
                              Basic Maintenance for your home
                            </li>
                            <li class="single-price-li">
                              2 hours covers the basics plus more
                            </li>
                            <li class="single-price-li">
                              Save on any emergencies or projects
                            </li>
                            <li class="single-price-li">
                              Pays for itself in just the monthly fee, savings
                              happen immediately
                            </li>
                          </ul>
                        </div>
                        <p class="single-price-note-para">
                          *Materials not included
                        </p>
                        <p> *3 Month Minimum Commitment </p>

                        <div class="view-more-btn-div">
                          <a
                            class="tier-view-more-btn-anch"
                            onClick={handleBasic}
                          >
                            {showBasic}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-6 col-12 mb-3 text-center d-flex align-items-center justify-content-center">
                      <iframe
                        className="video-container-youtube-plans"
                        width="1024"
                        height="576"
                        src="https://www.youtube.com/embed/ezai73L8poo"
                        title="The Basic Complete Home Care Plan #handyman #phoenix #home #repair"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div class="single-price-get-in-touch-div">
                      <Link
                        to="https://clienthub.getjobber.com/client_hubs/0826a40f-f07c-4e1e-984e-24d55cb2a044/public/work_request/new"
                        class="single-price-get-in-touch-text"
                      >
                        Book Online
                      </Link>
                    </div>
                  </div>
                </div>

                {/* <!-- Second div started --> */}
                <div class="single-price-listing-div premium-pricing-list my-5">
                  <div class="row mx-auto single-price-listing-wrapper">
                  <div className="col-lg-7 col-md-6 col-sm-6 col-12 mb-3 text-center d-flex align-items-center justify-content-center">
                      <iframe
                        className="video-container-youtube-plans"
                        width="1024"
                        height="576"
                        src="https://www.youtube.com/embed/m0kerqMPAAk"
                        title="Premium Complete Home Care Plan #handyman #phoenix #home #repair"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                      <div class="single-price-feature-div">
                        <h3 class="single-price-feature-text">Premium</h3>
                      </div>
                      <div class="single-price-amount-div">
                        <h3 class="single-price-amount-text">$189/mo</h3>
                      </div>
                      <div class="single-price-meta-div">
                        <h4 class="single-price-meta-head">What you get:</h4>
                        <ul class="single-price-listing-ul">
                          <li class="single-price-li">
                            2 hours of work per month
                          </li>
                          <li class="single-price-li">
                            Up to $25 and materials**
                          </li>
                          <li class="single-price-li">
                            Up to 3 months of rollover
                          </li>
                          {/* <li class="single-price-li">20% off on any work</li> */}
                          <li class="single-price-li">
                            20% off billable hours after credited work.
                          </li>
                        </ul>

                        <div style={{ display: `${premium}` }}>
                          <div class="benefits-heading">
                            <h4 class="single-price-meta-head benefits-heading-txt">
                              Plan Benefits:
                            </h4>
                          </div>

                          <ul class="single-price-listing-ul">
                            <li class="single-price-li">
                              Basic Maintenance for your home
                            </li>
                            <li class="single-price-li">
                              2 hours covers the basics plus more
                            </li>
                            <li class="single-price-li">
                              Save on any emergencies or projects
                            </li>
                            <li class="single-price-li">
                              Pays for itself in just the monthly fee, savings
                              happen immediately
                            </li>
                            <li class="single-price-li">
                              Roll over minutes allow for saved hours and
                              projects.
                            </li>
                            <li class="single-price-li">
                              Larger discount on standard services
                            </li>
                            <li class="single-price-li">
                              Material discounts (we don't mark up materials)
                              that is pure savings
                            </li>
                          </ul>
                        </div>

                        <p class="single-price-note-para">
                          *Materials not included <br />
                          **Rollover hours must be used in one sum(Can't be
                          broken up)
                          <br />
                          ***3 Month Minimum Commitment <br />
                        </p>

                        <div class="view-more-btn-div">
                          <a
                            class="tier-view-more-btn-anch"
                            onClick={handlePremium}
                          >
                            {showPremium}
                          </a>
                        </div>
                      </div>
                    </div>
                    

                    <div class="single-price-get-in-touch-div">
                      <Link
                        to="https://clienthub.getjobber.com/client_hubs/0826a40f-f07c-4e1e-984e-24d55cb2a044/public/work_request/new"
                        class="single-price-get-in-touch-text"
                      >
                        Book Online
                      </Link>
                    </div>
                  </div>
                </div>

                {/* <!-- ===== Second pricing div ends  --> */}
                <div class="single-price-listing-div mt-5">
                  <div class="row mx-auto single-price-listing-wrapper">
                    <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                      <div class="single-price-feature-div">
                        <h3 class="single-price-feature-text">VIP</h3>
                      </div>
                      <div class="single-price-amount-div">
                        <h3 class="single-price-amount-text">$249/mo</h3>
                      </div>
                      <div class="single-price-meta-div">
                        <h4 class="single-price-meta-head">What you get:</h4>
                        <ul class="single-price-listing-ul">
                          <li class="single-price-li">
                            2 hours of work per month
                          </li>
                          <li class="single-price-li">
                            Up to $25 of materials**
                          </li>
                          <li class="single-price-li">
                            Up to 3 months of roll over
                          </li>
                          <li class="single-price-li">
                            20% off billable hours after credited work
                          </li>
                          <li class="single-price-li">
                            Additional 5 free hours credit***
                          </li>
                          <li class="single-price-li">Transfer hours****</li>
                        </ul>

                        <div style={{ display: `${vip}` }}>
                          <div class="benefits-heading">
                            <h4 class="single-price-meta-head benefits-heading-txt">
                              Plan Benefits:
                            </h4>
                          </div>
                          <ul class="single-price-listing-ul">
                            <li class="single-price-li">
                              Basic Maintenance for your home
                            </li>
                            <li class="single-price-li">
                              2 hours covers the basics plus more
                            </li>
                            <li class="single-price-li">
                              Save on any emergencies or projects
                            </li>
                            <li class="single-price-li">
                              Pays for itself in just the monthly fee, savings
                              happen immediately
                            </li>
                            <li class="single-price-li">
                              Roll over minutes allow for saved hours and
                              projects.
                            </li>
                            <li class="single-price-li">
                              Larger discount on standard services
                            </li>
                            <li class="single-price-li">
                              Material discounts (we don't mark up materials)
                              that is pure savings
                            </li>
                            <li class="single-price-li">
                              1 time 5 hour credit of free labor, can be yours
                              or donated
                            </li>
                            <li class="single-price-li">
                              Can donate your monthly hours as gifts ( 4 times a
                              year)
                            </li>
                            <li class="single-price-li">
                              Priority booking means your jobs get done faster
                            </li>
                          </ul>
                        </div>

                        <p class="single-price-note-para">
                          *Materials not included <br />
                          **Included roll over unused hours (Once a month
                          without broken up) <br />
                          ***That can be saved and used at any time. (One time
                          upon joining) <br />
                          ****To friends & family up to 4 times per year
                          <br />
                          ****3 Month Minimum Commitment
                          <br />
                        </p>

                        <div
                          class="view-more-btn-div-three"
                          style={{ marginTop: "0px !important" }}
                        >
                          <a
                            class="tier-view-more-btn-anch"
                            onClick={handleVip}
                          >
                            {showVip}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-6 col-12 mb-3 text-center d-flex align-items-center justify-content-center">
                      <iframe
                        className="video-container-youtube-plans"
                        width="1024"
                        height="576"
                        src="https://www.youtube.com/embed/UGbIB_Kt40M"
                        title="V.I.P. Complete Home Care Plan #handyman #phoenix #repair"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div class="single-price-get-in-touch-div">
                      <Link
                        to="https://clienthub.getjobber.com/client_hubs/0826a40f-f07c-4e1e-984e-24d55cb2a044/public/work_request/new"
                        class="single-price-get-in-touch-text"
                      >
                        Book Online
                      </Link>
                    </div>
                  </div>
                </div>

                {/* <!-- ===== Third div ends here  --> */}
              </div>
            </div>
          </div>
        </section>
        <section class="recommended-sect">
          <div class="container">
            <div class="row">
              <div class="col-12 recommended-head-div">
                <div class="plans-section-head-div">
                  <h3 class="plans-sect-head-text">
                    Recommended Monthly Maintenance Plan
                  </h3>
                </div>
              </div>
            </div>

            <div class="row recommended-images-row pb-5 mb-3">
              <div class="col-md-6 rec-img-col">
                <div class="rec-img-wrapper">
                  <img src="./images/rec-img-1.jpg" alt="" class="rec-img" />
                </div>
              </div>
              <div class="col-md-6 rec-img-col">
                <div class="rec-img-wrapper">
                  <img src="./images/rec-img-2.jpg" alt="" class="rec-img" />
                </div>
              </div>
            </div>
            <div class="row recommended-plan-inner-content">
              <div class="col-md-6 recommended-plan-single-col border-rt border-btm">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">January</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      January Test smoke and carbon monoxide detectors.
                    </li>
                    <li class="single-meta-li">
                      Replace batteries in smoke and carbon monoxide detectors.
                    </li>
                    <li class="single-meta-li">
                      Vacuum your refrigerator coils.
                    </li>
                    <li class="single-meta-li">
                      Inspect, change out HVAC filters.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-6 recommended-plan-single-col border-btm recommended-plan-second-col">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">February</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      Test your water heater's pressure relief valve.
                    </li>
                    <li class="single-meta-li">
                      Check all locks and deadbolts on your doors and windows.
                    </li>
                    <li class="single-meta-li">
                      Check all electrical elements (GFI, outlets, and switches)
                    </li>
                  </ul>
                </div>
              </div>

              {/* <!-- 3 & 4 --> */}
              <div class="col-md-6 recommended-plan-single-col border-rt border-btm">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">March</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      Inspect your home's exterior for wood rot damage.
                    </li>
                    <li class="single-meta-li">
                      De-Winterize exterior water valves and lines.
                    </li>
                    <li class="single-meta-li">
                      Inspect basement and crawl spaces for moisture and mold.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-6 recommended-plan-single-col border-btm recommended-plan-second-col">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">April</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      Test smoke and carbon monoxide detectors.
                    </li>
                    <li class="single-meta-li">
                      Inspect, change out HVAC filters.
                    </li>
                    <li class="single-meta-li">
                      Check gutters and other exterior drainage.
                    </li>
                  </ul>
                </div>
              </div>

              {/* <!-- 5 & 6 --> */}

              <div class="col-md-6 recommended-plan-single-col border-rt border-btm">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">May</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      Inspect your home's exterior for wood rot damage.
                    </li>
                    <li class="single-meta-li">
                      De-Winterize exterior water valves and lines.
                    </li>
                    <li class="single-meta-li">
                      Inspect basement and crawl spaces for moisture and mold.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-6 recommended-plan-single-col border-btm recommended-plan-second-col">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">June</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      Check & clean dryer vent, other exhaust vents to home
                      exterior.
                    </li>
                    <li class="single-meta-li">
                      Inspect plumbing for leaks, clean aerators on faucets.
                    </li>
                    <li class="single-meta-li">
                      Check & repair all weather stripping.
                    </li>
                  </ul>
                </div>
              </div>

              {/* <!-- 7 & 8 --> */}
              <div class="col-md-6 recommended-plan-single-col border-rt border-btm">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">July</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      Test smoke and carbon monoxide detectors.
                    </li>
                    <li class="single-meta-li">
                      Replace batteries in smoke and carbon monoxide detectors.
                    </li>
                    <li class="single-meta-li">
                      Vacuum your refrigerator coils.
                    </li>
                    <li class="single-meta-li">
                      Inspect, change out HVAC filters.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-6 recommended-plan-single-col border-btm recommended-plan-second-col">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">August</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      Test your water heater's pressure relief valve.
                    </li>
                    <li class="single-meta-li">
                      Check interior drywall for damage and touchups as needed.
                    </li>
                    <li class="single-meta-li">
                      Check all patios and porches for loose nails, boards, hand
                      rails, etc.
                    </li>
                  </ul>
                </div>
              </div>

              {/* <!-- 9 & 10 --> */}

              <div class="col-md-6 recommended-plan-single-col border-rt border-btm">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">September</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      Check damper in fireplace for obstructions, such as birds
                      nests.
                    </li>
                    <li class="single-meta-li">Test sump pump.</li>
                    <li class="single-meta-li">
                      Inspect and maintain garbage disposal.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-6 recommended-plan-single-col border-btm recommended-plan-second-col">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">October</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      Test smoke and carbon monoxide detectors.
                    </li>
                    <li class="single-meta-li">
                      Inspect, change out HVAC filters.
                    </li>
                    <li class="single-meta-li">
                      Flush hot water heater and remove sediment.
                    </li>
                  </ul>
                </div>
              </div>

              {/* <!-- 11 & 12 --> */}

              <div class="col-md-6 recommended-plan-single-col border-rt">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">November</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      Winterize all exterior faucets and pipes.
                    </li>
                    <li class="single-meta-li">
                      Remove showerheads and clean sediment.
                    </li>
                    <li class="single-meta-li">
                      Inspect all water filtration system filters: water
                      softener, refrigerator, entire home, etc.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-6 recommended-plan-single-col recommended-plan-second-col">
                <div class="rec-plan-single-head-div">
                  <h4 class="rec-head-text">December</h4>
                </div>
                <div class="rec-plan-sing-meta-div">
                  <ul class="single-meta-ul">
                    <li class="single-meta-li">
                      Check interior caulking (bathtubs and sinks).
                    </li>
                    <li class="single-meta-li">
                      Check & repair all weather stripping.
                    </li>
                    <li class="single-meta-li">
                      Tighten any handles, knobs, racks, etc. Evaluate anything
                      that could have a loose screw.
                    </li>
                  </ul>
                </div>
              </div>
              {}

              {/* <!-- == End months here  --> */}
            </div>
            <div class="row rec-note-row">
              <div class="col-12 rec-note-col">
                <p class="rec-note-para">
                  *Each owner can customize their needs monthly.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Rates />
        <section class="call-to-act-sect">
          <div class="call-to-act-wrapper">
            <div class="container">
              <div class="row">
                <div class="col-12 call-to-act-main-col">
                  <div class="call-to-act-col-wrapper">
                    <div class="call-act-content">
                      <p class="call-to-act-para">
                        Protect your home by automating your maintenance care
                        plan
                      </p>
                    </div>
                    <div class="call-to-act-btn-div">
                      <a
                        href="https://breakthroughhandyman.com/contact"
                        class="call-to-act-btn-anch"
                      >
                        Get Started Today
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

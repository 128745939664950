import React from "react";
import CustomButton from "./CustomButton";
import "./ServicePackage.css";

const ServicePackage = ({
  packageName,
  items,
  buttonText,
  onButtonClick,
  price,
  singleVisit,
  discounts,
  imagePosition = "right",
}) => {
  const [imageItem, ...textItems] = items;

  return (
    <div className="service-package container py-5 px-4 mt-6">
      <h2 className="service-package-title fw-bold">{packageName}</h2>
      <div
        className={`d-flex flex-column flex-lg-row align-items-center ${
          imagePosition === "right" ? "" : "flex-lg-row-reverse"
        }`}
      >
        <div className="service-package-text text-center text-lg-start p-4">
          {textItems.map((item, index) => (
            <p key={index} className="service-package-item mb-2">
              {item}
            </p>
          ))}

          <div className="service-package-price my-3">
            <span className="service-package-original-price">${price}</span>
            <span className="service-package-discounted-price">
              {singleVisit}
            </span>
            <small className="service-package-note d-block">{discounts}</small>
          </div>

          <div className="service-package-button">
            <CustomButton text={buttonText} onClick={onButtonClick} />
          </div>
        </div>

        {imageItem && (
          <div className="service-package-image-container">
            <img
              src={imageItem.image}
              alt={imageItem.alt}
              className="service-package-image rounded"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ServicePackage;

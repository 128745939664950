import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Navbar() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="navbar-bg-color">
      <div className="px-lg-5 main-nav">
        <nav class="navbar navbar-expand-lg navbar-dark navigation-bar cstm-nav-setting">
          <div class="container-fluid">
            <Link class="navbar-brand" to="/" onClick={scrollToTop}>
              <img
                className="nav-logo"
                src="./images/logo.png"
                alt="logo BreakThrough "
              />
            </Link>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse navmen"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav  mb-2 mb-lg-0 ">
                {/* <li class="nav-item">
                  <Link class="nav-link active" onClick={scrollToTop} to="/">
                    Home
                  </Link>
                </li> */}
                {/* <li class="nav-item">
                  <Link
                    class="nav-link "
                    onClick={scrollToTop}
                    to="/care-plans"
                  >
                    Complete Care Plans
                  </Link>
                </li> */}
                <li class="nav-item">
                  <Link class="nav-link" onClick={scrollToTop} to="/services">
                    Services
                  </Link>
                </li>
                <li class="nav-item">
                  <HashLink class="nav-link" smooth to="/#who-sect">
                    Who we are
                  </HashLink>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" onClick={scrollToTop} to="/careers">
                    Careers
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    class="nav-link"
                    onClick={scrollToTop}
                    to="/testimonials"
                  >
                    Testimonials
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    class="nav-link"
                    onClick={scrollToTop}
                    to="/christmas"
                  >
                    Christmas
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;

import React from "react";
import { FaCog, FaDollarSign, FaClock, FaStar } from "react-icons/fa";
import CustomButton from "./common/CustomButton";
import FruitsImage from "../../../assets/images/christmas/quote/fruits.png";
import ChristmasTree from "../../../assets/images/christmas/quote/christmas-tree.png";
import "./GetQuote.css"; // Custom CSS for styling

const GetQuote = () => {
  const handleButtonClick = () => {
    window.open("https://www.thecustomerfactor.com/bid.php?id=am9zaHVham1lNQ==", "_blank");
  };

  return (
    <section
      className="get-quote-section position-relative py-5 px-4 text-lg-start overflow-hidden"
      style={{ backgroundColor: "#FFEEEE" }}
    >
      {/* Background Decorations */}
      <div
        className="position-absolute top-0 start-0 get-quote-fruits"
        style={{
          backgroundImage: `url(${FruitsImage})`,
        }}
      ></div>
      <div
        className="position-absolute bottom-0 end-0 get-quote-tree"
        style={{
          backgroundImage: `url(${ChristmasTree})`,
        }}
      ></div>

      {/* Main Content */}
      <div className="container max-w-6xl position-relative z-10">
        <h2 className="get-quote-title">
          Why Homeowners Trust Breakthrough Handyman for Christmas Decorators
        </h2>
        <div className="get-quote-underline"></div>

        <div className="feature-list mt-5">
          <div className="feature-item d-flex align-items-start mb-3">
            <FaCog className="feature-icon" />
            <div className="ms-3">
              <strong>Flexible & Customizable:</strong> We work around your
              style and decorations, offering tailored options.
            </div>
          </div>

          <div className="feature-item d-flex align-items-start mb-3">
            <FaDollarSign className="feature-icon" />
            <div className="ms-3">
              <strong>Cost-Effective & Transparent:</strong> No mark-up on
              materials; only hourly charges for time and labor.
            </div>
          </div>

          <div className="feature-item d-flex align-items-start mb-3">
            <FaClock className="feature-icon" />
            <div className="ms-3">
              <strong>Efficient Service:</strong> Quick installations, with an
              average roofline setup completed within an hour.
            </div>
          </div>

          <div className="feature-item d-flex align-items-start mb-3">
            <FaStar className="feature-icon" />
            <div className="ms-3">
              <strong>Experienced Professionals:</strong> Decade-long expertise
              in holiday decorations for an effortless experience.
            </div>
          </div>
        </div>

        <div className="mt-5">
          <CustomButton
            text="Schedule Your Appointment"
            className="get-quote-button"
            onClick={handleButtonClick}
          />
        </div>
      </div>
    </section>
  );
};

export default GetQuote;

import AllOutPackages from "./AllOutPackageServices";
import CommonServices from "./CommonServices";
import MediumPackageServices from "./MediumPackageServices";
import "./Packages.css";

const Packages = () => {
  return (
    <>
      <div className="container py-5 mt-5">
        <h2 className="packages-heading">
          Our Christmas Light Installation Packages
        </h2>
        <div className="d-flex flex-column gap-5">
          <CommonServices />
          <MediumPackageServices />
          <AllOutPackages />
        </div>
      </div>
    </>
  );
};

export default Packages;

import React from "react";
import residentialImage from "../../../assets/images/christmas/services/basic_package.png";
import ServicePackage from "./common/ServicePackage";

const CommonServices = () => {
  const handleButtonClick = () => {
    window.open("https://www.thecustomerfactor.com/bid.php?id=am9zaHVham1lNQ==", "_blank");
  };

  const packageDetails = {
    packageName: "Basic Package",
    items: [
      {
        image: residentialImage, // Path to the image file
        alt: "House decorated with Christmas lights",
      },
      "Roofline lighting and Entrance decoration",
      "Less than 1 hour if lights are ready onsite",
    ],
    price: 99,
    singleVisit: "$84.15/hr for a single visit",
    discounts: "(discounts apply for additional hours booked)",
    buttonText: "Schedule Basic Setup Today!",
    onButtonClick: handleButtonClick
  };

  return (
    <div>
      <ServicePackage
        packageName={packageDetails.packageName}
        items={packageDetails.items}
        buttonText={packageDetails.buttonText}
        onButtonClick={packageDetails.onButtonClick}
        imagePosition="right"
        price={packageDetails.price}
        singleVisit={packageDetails.singleVisit}
        discounts={packageDetails.discounts}
      />
    </div>
  );
};

export default CommonServices;

import React from 'react';
import './BookingBanner.css';

function BookingBanner() {
  return (
    <div className="booking-banner">
      <h2>Call for Booking</h2>
      <div className="phone-number">
        <span role="img" aria-label="phone">📞</span>
        <a href="tel:+16029385415" className="phone-link">602-938-5415</a>
      </div>
      <div className="not-licensed">NOT A LICENSED CONTRACTOR</div>
    </div>
  );
}

export default BookingBanner;

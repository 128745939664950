import React from "react";
import "./CustomizationSection.css";
import CustomButton from "../common/CustomButton";

const CustomizationSection = () => {
  const handleCallClick = () => {
    window.location.href = "tel:+16029385415";
  };

  return (
    <section className="customization-section d-flex justify-content-center align-items-center">
      <div className="customization-button">
        <CustomButton
          text="Call for Any Customization"
          onClick={handleCallClick}
        />
      </div>
    </section>
  );
};

export default CustomizationSection;

import React, { useEffect } from "react";
import Map from "../ThirdpartyLibrary/Map";

function Home() {
  useEffect(() => {
    // window.location.reload();
  }, []);

  return (
    <div>
      <div class="top-bar-wrapper">
        <div class="container">
          <div class="row text-center">
            <div class="col-12 top-bar-info-main">
              <p class="top-bar-text-link">
                <img
                  src="https://breakthroughhandyman.com/wp-content/themes/breakthrough/images/topbar-phone-icon.png"
                  alt=""
                  class="top-bar-img"
                />
                Call{" "}
                <a href="tel:602-938-5415" class="top-bar-phone">
                  602-938-5415
                </a>
                <span class="top-bar-second-text">
                  {" "}
                  To Schedule your appointment today!
                </span>
              </p>
              <p class="lincensed-para">Not a Licensed Contractor</p>
            </div>
          </div>
        </div>
      </div>

      {/* ==== Hero Banner Section ==== */}

      <section class="hero-banner-sect" id="banner-sect">
        <div class="hero-banner-sect-wrapper">
          <div class="container">
            <div className="video-container" style={{ zIndex: "-10" }}>
              <video autoPlay muted loop id="myVideo">
                <source
                  src="./videos/home-video-compressed.mp4"
                  type="video/mp4"
                />
              </video>
            </div>

            <div class="row" style={{ color: "black" }}>
              <div class="col banner-head-col">
                <div class="banner-head-wrapper">
                  <h2 class="banner-head-text">
                    {/* <!-- When Something Breaks <br class="breakthroug-br"/>
							 Call Breakthrough! --> */}
                    When Something Breaks <br class="breakthroug-br" /> Call
                    Breakthrough!{" "}
                  </h2>
                </div>
                <div class="banner-desc-wrapper">
                  <p class="banner-desc-text">
                    {/* <!-- Insured Professional Services, and <br class="breakthroug-br"/>
							Serving the Phoenix Metro Area --> */}
                    Insured Professional Services, and{" "}
                    <br class="breakthroug-br" />
                    Serving the Phoenix Metro Area
                  </p>
                </div>
                <div class="row contact-form-manin-row">
                  <div
                    class="col-12 contact-form-main-col"
                    style={{ textAlign: "center" }}
                  >
                    <a
                      class="ctaButton"
                      href="https://www.thecustomerfactor.com/bid.php?id=am9zaHVham1lNQ=="
                      target="_blank"
                    >
                      Click Here to Schedule An Appointment
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--			<div class="row banner-anch-row">
				<div class="col banner-anch-wrapper">
					 <button name="button" type="button" id="work-request-button-0826a40f-f07c-4e1e-984e-24d55cb2a044" style="display: inline-flex;color:#ffffff;background-color:#7db00e;align-items:center;justify-content:center;min-height:36px;padding:8px 12px;font-family:'Noto Sans',Helvetica,Arial,sans-serif;font-size:14px;font-weight:700;line-height:1;-webkit-font-smoothing:antialiased;-webkit-appearance:none;text-align:center;text-decoration:none;border:1px solid transparent;border-radius:4px;outline:none;cursor:pointer;vertical-align:middle;">Book Online</button>
 					<a href="#" class="banner-anch-text" id="work-request-button-0826a40f-f07c-4e1e-984e-24d55cb2a044">
						  Book an appointment 
											</a>
				</div>
			</div>
-->             */}
            <div class="banner-scroll-btn-row">
              <div class="col banner-scroll-btn-col">
                <a href="#footer-sect" class="banner-scroll-btn-img-txt">
                  <img
                    src="https://breakthroughhandyman.com/wp-content/uploads/2022/01/mouse.png"
                    alt=""
                    class="banner-scroll-btn-img"
                  />
                  <br class="breakthroug-br" />
                  <span class="banner-scroll-txt">Scroll</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Greate Value section */}
      <section class="great-value-sect">
        <div class="great-value-sect-wrapper">
          <div class="container">
            <div class="row great-value-sect-row">
              <div class="col great-value-sect-col">
                <div class="great-value-sect-col-wrapper">
                  <div class="great-value-first-head">
                    {/* <!-- <p class="great-value-first-head-text">
						    	Fair Pirce <span class="great-value-plus-symbol">+</span> Nicely Complete Job
						    </p>
						    <p class="great-value-equal-to-sign">
						    	=
						    </p>
                             
                             <p class="great-value-bordered-head">
                             	Great Value
                             </p> --> */}
                    <p class="great-value-first-head-text">
                      Quality Professional Work{" "}
                      <span class="great-value-plus-symbol">+</span> Timely
                      Service
                    </p>
                    <p class="great-value-equal-to-sign">=</p>
                    <p class="great-value-bordered-head">Great Value</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="our-commitment-sect" id="services-sect">
        <div class="our-commitment-sect-wrapper">
          <div class="container">
            <div class="row our-commitment-head-row">
              <div class="col our-commitment-head-col">
                <h3 class="our-commitment-head-text">Our Commitment</h3>
              </div>
            </div>
            <div class="row our-commitment-listing-row">
              <div class="col-lg-4 col-md-6 our-commitmen-single-listing">
                <div class="our-commitment-single-listing-wrapper">
                  <div class="single-commitment-icon-div">
                    <img
                      src="./images/great-value-icon.png"
                      alt=""
                      class="single-commitment-icon"
                    />
                  </div>
                  <div class="single-commitment-meta-div">
                    <h3 class="single-commitment-head-text">Great Value </h3>
                    <p class="single-commitment-desc-para">
                      <p>
                        Timely warranted services performed professionally, and
                        affordably. Breakthrough Handyman is the best value.
                      </p>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 our-commitmen-single-listing">
                <div class="our-commitment-single-listing-wrapper">
                  <div class="single-commitment-icon-div">
                    <img
                      src="./images/timerepair-icon.png"
                      alt=""
                      class="single-commitment-icon"
                    />
                  </div>
                  <div class="single-commitment-meta-div">
                    <h3 class="single-commitment-head-text">
                      Timely Repair &#038; Service{" "}
                    </h3>
                    <p class="single-commitment-desc-para">
                      <p>We can potentially show up today if needed.</p>
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 our-commitmen-single-listing">
                <div class="our-commitment-single-listing-wrapper">
                  <div class="single-commitment-icon-div">
                    <img
                      src="./images/saving-icon.png"
                      alt=""
                      class="single-commitment-icon"
                    />
                  </div>
                  <div class="single-commitment-meta-div">
                    <h3 class="single-commitment-head-text">
                      Saving the Homeowner{" "}
                    </h3>
                    <p class="single-commitment-desc-para">
                      <p>
                        We are the professional AFFORDABLE alternative to the
                        expensive contractors.
                      </p>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 our-commitmen-single-listing">
                <div class="our-commitment-single-listing-wrapper">
                  <div class="single-commitment-icon-div">
                    <img
                      src="./images/professional-icon.png"
                      alt=""
                      class="single-commitment-icon"
                    />
                  </div>
                  <div class="single-commitment-meta-div">
                    <h3 class="single-commitment-head-text">
                      Professional Appearance{" "}
                    </h3>
                    <p class="single-commitment-desc-para">
                      <p>
                        The tech will come dressed to work with all the tools
                        needed to complete the job.
                      </p>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 our-commitmen-single-listing">
                <div class="our-commitment-single-listing-wrapper">
                  <div class="single-commitment-icon-div">
                    <img
                      src="./images/knowledge-icon.png"
                      alt=""
                      class="single-commitment-icon"
                    />
                  </div>
                  <div class="single-commitment-meta-div">
                    <h3 class="single-commitment-head-text">
                      Knowledge &#038; Experience{" "}
                    </h3>
                    <p class="single-commitment-desc-para">
                      <p>
                        Our handyman have years experience in the different
                        handyman niches.
                      </p>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 our-commitmen-single-listing">
                <div class="our-commitment-single-listing-wrapper">
                  <div class="single-commitment-icon-div">
                    <img
                      src="./images/clock-icon.png"
                      alt=""
                      class="single-commitment-icon"
                    />
                  </div>
                  <div class="single-commitment-meta-div">
                    <h3 class="single-commitment-head-text">
                      Show Up on Time{" "}
                    </h3>
                    <p class="single-commitment-desc-para">
                      <p>Your time is valuable, we will arrive as scheduled.</p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section class="contact-form-sect" id="contact-sect">
        <div class="contact-form-sect-wrapper">
          <div class="container">
            {/* <!-- 	<div class="row contact-sect-head-row">
				<div class="col-12 contact-head-col">
					<h3 class="contact-head-text">
						CONTACT US
					</h3>
				</div>
				<div class="col-12 contact-head-desc-div">
					<p class="contact-desc-para">
						PLEASE FILL IN THE INFORMATION BELOW AND WE'LL GET BACK TO YOU WITHIN 24 HOURS.
					</p>
				</div>
			</div> --> */}
            <div class="row contact-form-manin-row">
              <div class="col-12 contact-form-main-col">
                <a
                  class="sch-appointment"
                  href="https://www.thecustomerfactor.com/bid.php?id=am9zaHVham1lNQ=="
                  target="_blank"
                >
                  Click Here to Schedule An Appointment
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}

      <section class="who-we-are-sect" id="who-sect">
        <div class="container">
          <div class="row ">
            <div class="col-12 who-we-are-head-div">
              <h3 class="who-we-are-head-text">
                <span className="border-yellow-bottom">Who We Are</span>
              </h3>
            </div>
            <div class="col-12 who-we-are-text-col-div">
              <p class="who-we-are-para">
                Breakthrough handyman services is your trusted and reliable
                source for home repairs and remodeling. In our decade plus of
                being full time real estate professionals, we have found a huge
                need in the handyman industry. There is no shortage of
                handy-people but there is a huge shortage of professional
                handy-people, who treat it like a truly PROFESSIONAL business!
              </p>
              <p></p>
              <p class="who-we-are-para">
                We answered all of these needs in the industry. We can assist
                you with:
                <br />
                basic home repairs, remodeling, updates, upgrades, odds and
                ends, your &#8220;to-do&#8221; lists, so many items, too many to
                list here.
              </p>
            </div>
            {/* ====RWL banner ==== */}

            <div class="row mt-5 ">
              <div class="col-12 who-we-are-head-div pl-5">
                <h3 class="who-we-are-head-text">
                  <span className="border-yellow-bottom">
                    {" "}
                    Our Client Reviews
                  </span>
                </h3>
              </div>
              <Map />
            </div>

            <div>
              <div id="reachForm"></div>
            </div>
          </div>
        </div>
      </section>
      {/* <zapier-interfaces-chatbot-embed
        is-popup="false"
        chatbot-id="clyerlsz6000i414g32wzmpzm"
        height="600px"
        width="400px"
      ></zapier-interfaces-chatbot-embed> */}
      {/* <script async type='module' src='https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js'></script> */}
      {/* <zapier-interfaces-chatbot-embed
        is-popup="true"
        chatbot-id="clyi737ft0018463a0e3sn3cq"
        height="600px"
        width="400px"
      ></zapier-interfaces-chatbot-embed> */}
    </div>
  );
}

export default Home;

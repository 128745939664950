import React from "react";
import promoImage from "../../../assets/images/christmas/promo.png"; // Adjust the path as necessary
import "./PromoBanner.css"; // Import custom CSS

const PromoBanner = ({ subText = false }) => (
  <div className="promo-banner position-relative d-flex justify-content-center align-items-center text-center">
    {/* Background Image Overlay */}
    <div className="promo-banner-background position-absolute top-0 start-0 w-100 h-100"></div>

    {/* Text Content */}
    <div className="promo-banner-content d-flex flex-column gap-3 gap-md-5">
      {subText && (
        <h3 className="promo-subtext position-relative text-white">
          “Don’t Miss Out on a Stress-Free Holiday!”
        </h3>
      )}
      <h2 className="promo-main-text position-relative text-white">
        15% off Put Up and Take Down when booked together
      </h2>
    </div>
  </div>
);

export default PromoBanner;

import React from "react";
import residentialImage from "../../../assets/images/christmas/services/medium_package.png";
import ServicePackage from "./common/ServicePackage";

const MediumPackageServices = () => {
  const handleButtonClick = () => {
    window.open("https://www.thecustomerfactor.com/bid.php?id=am9zaHVham1lNQ==", "_blank");
  };


  const packageDetails = {
    packageName: "Medium Package",
    items: [
      {
        image: residentialImage,
        alt: "House decorated with Christmas lights",
      },
      "Includes Basic Package plus small yard displays ",
      "2-3 hours with two professionals",
    ],
    price: 89,
    singleVisit: "$75.65/hr for 2-3 calls",
    discounts: "(discounts apply for additional hours booked)",
    buttonText: "(Two people for larger setups)",
    onButtonClick: handleButtonClick
  };

  return (
    <div>
      <ServicePackage
        packageName={packageDetails.packageName}
        items={packageDetails.items}
        buttonText={packageDetails.buttonText}
        onButtonClick={packageDetails.onButtonClick}
        imagePosition="left"
        price={packageDetails.price}
        singleVisit={packageDetails.singleVisit}
        discounts={packageDetails.discounts}
      />
    </div>
  );
};

export default MediumPackageServices;

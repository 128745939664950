import Axios from "axios";
import React, { useState, useEffect } from "react";

export default function Careers() {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div>
        <h1 className="mid-heading">
          {" "}
          Why you should join Breakthrough Handyman?
        </h1>
      </div>
      <div className="container">
        <div className="container-video">
          <iframe
            className="responsive-iframe"
            width="1024"
            height="576"
            src="https://www.youtube.com/embed/-5RFIvOtWCM"
            title="Looking For Phoenix Area Handyman #handyman #homeimprovement"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <p className="my-5">
        <i>
          <span style={{ fonWeight: "100", fontStyle: "italic" }}>
            Breakthrough Handyman Services is an equal opportunity employer and
            does not discriminate against any employee or applicant for
            employment because of race, color, sex, age, national origin,
            religion, sexual orientation, gender identity, status as a veteran,
            and basis of disability or any other federal, state or local
            protected class.
          </span>
        </i>
      </p>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import jobData from "./RequestedJobData/requestedData";
import Rates from "../Rates/Rates";

function Services() {
  return (
    <div>
      <section class="breakthrough-page-banner-area">
        <div class="container">
          <div class="video-container" style={{ zIndex: "-10" }}>
            <video autoPlay muted loop id="myVideo">
              <source src="./videos/services.mp4" type="video/mp4" />
            </video>
          </div>

          <div class="row">
            <div class="col-12 banner-head-div">
              <h2 class="banner-head-txt">Top Requested Jobs</h2>
              <Link to="/terms-conditions">
                <h4
                  className="my-5"
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  <i>Terms and Conditions</i>{" "}
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section class="py-5">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h3 class="fs-2 fw-bold text-warning mb-5">
                General Hourly Rates
              </h3>
              <ul class="list-styled-dash">
                <li className="fs-4 mb-3">1-Person Rate:</li>
                <ul className="ms-4">
                  <li class="fs-4 mb-4">1 Hour Minimum Call: $99</li>
                  <li class="fs-4 mb-4">2-3 Hour Call: $89/hour</li>
                  <li class="fs-4 mb-4">4-6 Hour Call: $85/hour</li>
                  <li class="fs-4 mb-4">7-8 Hour Call: $79/hour</li>
                  <li class="fs-4 mb-4">9-12 Hour Call: $75/hour</li>
                </ul>
                <li className="fs-4 mb-3">2-Person Rate:</li>

                <ul className="ms-4">
                  <li className="fs-4 mb-3">1 Hour Minimum: $195</li>
                  <li className="fs-4 mb-3">2-3 Hour Call: $170 per hour</li>
                  <li className="fs-4 mb-3">4-6 Hour Call: $160 per hour</li>
                  <li className="fs-4 mb-3">
                    7 Hour Call Max: $1000 ($142.85 per hour, our best value)
                  </li>
                </ul>
              </ul>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <img
                src="./images/rates-img.png"
                alt="Rates Image"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <div class="fix-it-plan-main services-page-main">
        <section class="top-requested-sect">
          <div class="container">
            <div class="row">
              <div class="col-12 top-requested-head-div">
                <div class="plans-section-head-div">
                  <h3 class="plans-sect-head-text">
                    <span className="border-yellow-bottom">
                      Top Requested Jobs
                    </span>
                  </h3>
                </div>
              </div>
              <h6>
                <p className="">Single Job Flat Rates: </p>
                <i className="text-muted">
                  If the job takes 30 minutes or less, the flat rate is $99. 1
                  Hour Store Time for Materials, if needed. There is no mark up
                  on materials. The store will call the owner for material
                  payment directly.
                </i>
                <br />
                <br />
                <i>
                  Storetime for Materials:{" "}
                  <span style={{ fontSize: "1.2rem"}} className="text-warning">
                    1 hour minimum call
                  </span>
                </i>
                <br />
                <br />
                <i> *Actual Time and Materials May Vary</i>{" "}
              </h6>
            </div>

            <div className="row top-requested-inner-content">
              {jobData.map((job) => {
                return (
                  <div
                    key={job.id}
                    className={`col-md-6 col-sm-12 top-requested-single-col border-btm ${job.section} mb-4`}
                  >
                    <div className="top-requested-sing-meta-div p-3">
                      <div className="row align-items-start">
                        <div className="col-1">
                          <p className="top-req-number-span font-weight-bold">
                            {job.id})
                          </p>
                        </div>
                        <div className="col-11">
                          <p
                            className="top-single-meta-text m-0 font-weight-bold"
                            style={{ fontSize: "1.5rem", color: "#333" }}
                          >
                            {job.job}
                          </p>
                          <p
                            className="top-single-meta-cost m-0"
                            style={{ fontSize: "1.3rem", fontWeight: "500" }}
                          >
                            Price:{" "}
                            <span className="text-warning">{job.workCost}</span>
                          </p>
                          {job.details && (
                            <p
                              className="top-single-meta-details mt-2"
                              style={{ fontSize: "1.2rem", color: "#6c757d" }}
                            >
                              {job.details}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section class="call-to-action">
          <div class="container">
            <div class="row contact-form-manin-row">
              <div
                class="col-12 contact-form-main-col "
                style={{ textAlign: "center" }}
              >
                <a
                  class="ctaButton-white"
                  href="https://www.thecustomerfactor.com/bid.php?id=am9zaHVham1lNQ=="
                  target="_blank"
                >
                  Click Here to Schedule An Appointment
                </a>
              </div>
            </div>
          </div>
        </section>
        <Rates />
        {/* <section class="py-5 bg-light">
          <h1 className="text-center text-dark mb-4"> General Procedures </h1>
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="accordion mt-5" id="generalProceduresAccordion">

                  <div class="accordion-item border border-dark rounded-3 mb-3">
                    <h2 class="accordion-header" id="headingBeforeStarting">
                      <button class="accordion-button bg-warning text-dark border-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBeforeStarting" aria-expanded="true" aria-controls="collapseBeforeStarting">
                        Before Starting the Job
                      </button>
                    </h2>
                    <div id="collapseBeforeStarting" class="accordion-collapse collapse show" aria-labelledby="headingBeforeStarting" data-bs-parent="#generalProceduresAccordion">
                      <div class="accordion-body bg-white text-dark">
                        <ul class=" mb-0">
                          <li>Verify all job details and materials with the customer.</li>
                          <li>Confirm estimated time and cost before starting the job.</li>
                          <li>Notify the office if a store visit is required.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item border border-dark rounded-3 mb-3">
                    <h2 class="accordion-header" id="headingMultipleJobs">
                      <button class="accordion-button bg-warning text-dark border-dark collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMultipleJobs" aria-expanded="false" aria-controls="collapseMultipleJobs">
                        Handling Multiple Jobs
                      </button>
                    </h2>
                    <div id="collapseMultipleJobs" class="accordion-collapse collapse" aria-labelledby="headingMultipleJobs" data-bs-parent="#generalProceduresAccordion">
                      <div class="accordion-body bg-white text-dark">
                        <ul class=" mb-0">
                          <li>Calculate the time for each job separately and then combine the total time. Apply discounts based on total time.</li>
                          <li>Confirm the total time and cost with the customer before starting.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item border border-dark rounded-3 mb-3">
                    <h2 class="accordion-header" id="headingChristmasLightRemoval">
                      <button class="accordion-button bg-warning text-dark border-dark collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseChristmasLightRemoval" aria-expanded="false" aria-controls="collapseChristmasLightRemoval">
                        Christmas Light Removal
                      </button>
                    </h2>
                    <div id="collapseChristmasLightRemoval" class="accordion-collapse collapse" aria-labelledby="headingChristmasLightRemoval" data-bs-parent="#generalProceduresAccordion">
                      <div class="accordion-body bg-white text-dark">
                        <ul class=" mb-0">
                          <li>Estimate half the time it took to install the lights for removal.</li>
                          <li>Apply a 10% discount if both installation and removal were booked at the same time.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item border border-dark rounded-3 mb-3">
                    <h2 class="accordion-header" id="headingCollectingPayment">
                      <button class="accordion-button bg-warning text-dark border-dark collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCollectingPayment" aria-expanded="false" aria-controls="collapseCollectingPayment">
                        Collecting Payment
                      </button>
                    </h2>
                    <div id="collapseCollectingPayment" class="accordion-collapse collapse" aria-labelledby="headingCollectingPayment" data-bs-parent="#generalProceduresAccordion">
                      <div class="accordion-body bg-white text-dark">
                        <ul class=" mb-0">
                          <li>Collect payment after the job is completed and customer approval is obtained.</li>
                          <li>Ensure all invoices are updated in the system before leaving the site.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 d-flex align-items-center">
                <img src="./images/rates-img.png" alt="Rates Image" class="img-fluid rounded-3 border border-dark" />
              </div>
            </div>
          </div>
        </section> */}

        <section class="call-to-act-sect">
          <div class="call-to-act-wrapper">
            <div class="container">
              <div class="row">
                <div class="col-12 call-to-act-main-col">
                  <div class="call-to-act-col-wrapper">
                    <div class="call-act-content">
                      <p class="call-to-act-para">
                        Protect your home by automating your maintenance care
                        plan
                      </p>
                    </div>

                    <div class="call-to-act-btn-div">
                      <a
                        href="https://breakthroughhandyman.com/contact"
                        class="call-to-act-btn-anch"
                      >
                        Get Started Today
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Services;

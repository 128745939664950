import React from "react";
import CustomerTerms from "../CustomerTerms/CustomerTerms";

const Rates = () => {
  return (
    <section className="rates-term-sect">
      <div className="container">
        <div className="row">
          <div className="col-md-6 rates-content-col">
            <div className="rates-content-wrapper">
              <div className="plans-section-head-div mb-3">
                <h3 className="plans-sect-head-text">Rates and Terms</h3>
              </div>
              <div className="additional-info-div w-90 mx-5">
                <div className="store-visits-div mb-4">
                  <h4 className="additional-info-heading text-warning">
                    Store Visits
                  </h4>
                  <li className="additional-info-text text-muted">
                    Add 1 hour for store visits if materials are not onsite.
                    Always confirm with the customer.
                  </li>
                </div>
                <div className="multiple-jobs-div mb-4">
                  <h4 className="additional-info-heading text-warning">
                    Multiple Jobs
                  </h4>
                  <li className="additional-info-text text-muted">
                    Calculate the total time for multiple jobs by adding the
                    individual times together. Apply hourly pricing for total
                    time based on general hourly rates.
                  </li>
                </div>
                <div className="cancellation-fees-div mb-4">
                  <h4 className="additional-info-heading text-warning">
                    Cancellation Fees
                  </h4>
                  <ul className="cancellation-fees-ul text-muted">
                    <li className="cancellation-fees-li">
                      Standard Cancellation Fee (within 24 hours): $99 (charged
                      to customer).
                    </li>
                    <li className="cancellation-fees-li">
                      Same-Day/Onsite Cancellation Fee: $99 (charged to the
                      customer if they cancel onsite or less than 24 hours in
                      advance).
                    </li>
                  </ul>
                </div>

                {/* UPDATED START: Late Fee */}
                <div className="late-fees-div mb-4">
                  <h4 className="additional-info-heading text-warning">
                    Immediate Late Fee
                  </h4>
                  <li className="additional-info-text text-muted">
                    A $40 late fee will be charged to the customer if they are
                    not available for verification of job and payment within 30
                    minutes of the handyman finishing the job. Handymen need to
                    get to their next appointments on time.
                  </li>
                </div>
                {/* UPDATED END: Late Fee */}

                <div className="discounts-div mb-4">
                  <h4 className="additional-info-heading text-warning">
                    Discounts
                  </h4>
                  <li className="additional-info-text text-muted">
                    For scheduling Christmas Light Removal with installation,
                    apply a 10% discount for scheduling both at the same time.
                    For Christmas Light Removal, estimate half the time it took
                    to install.
                  </li>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column: General Procedures */}
          <div className="col-md-6 d-flex align-items-center">
            <div className="rates-content-wrapper">
              <div className="plans-section-head-div mb-3">
                <h3 className="plans-sect-head-text">General Procedures</h3>
              </div>
              <div className="col-12">
                <CustomerTerms />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rates;
